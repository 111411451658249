<template>
    <div class="clientcabin clientcabin-book">
        <v-container>
            <v-row justify="center" class="align-center pt-md-6">
                <v-col cols="12" lg="9" xl="7">
                    <p class="subheadline text-center">
                        Let's Talk About Growing Your Organic Traffic
                    </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center pt-md-4">
                <v-col cols="12" lg="9" xl="7">
                    <p class="subtitle text-center">
                        We'll Share Our 3 Step Done For You System That Helps
                        Increase Your Visibility & Boosts Your Brand's Authority
                        & Sales.
                    </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center pt-md-4">
                <v-col cols="12" lg="9" xl="7" class="text-center">
                    <v-btn
                        v-if="options?.booking_url"
                        :large="$vuetify.breakpoint.mdAndUp"
                        class="primary"
                        :href="options?.booking_url"
                        target="_blank"
                    >
                        Book Appointment
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center pt-md-6 text-center">
                <v-col cols="12" lg="9" xl="7">
                    <p>
                        Still Wondering If Our Amplification Campaigns Can Work
                        For You?
                    </p>
                    <p>
                        This Medical Device Brand Increased From $48,000/mo To
                        $735,000/mo In Sales With A 20,000% Increase In Traffic:
                    </p>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="d-flex align-center justify-center pb-md-10"
                >
                    <v-img
                        eager
                        src="@/assets/img/clientcabin/proposal/GA Traffic (dark).png"
                    />
                </v-col>
            </v-row>
        </v-container>

        <div class="blueish">
            <v-container class="py-md-8">
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pa-0">
                        <v-container class="faq">
                            <v-row>
                                <v-col class="text-center">
                                    <p class="subheadline">
                                        Frequently Asked Questions
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col
                                    :class="{
                                        'mb-n6': $vuetify.breakpoint.smAndDown
                                    }"
                                    cols="12"
                                    md="6"
                                >
                                    <v-expansion-panels
                                        v-for="(faq, index) in faq1"
                                        :key="index"
                                        flat
                                        class="mb-4"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header
                                                class="font-weight-medium text-subtitle-1"
                                            >
                                                {{ faq.question }}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                class="text-body-2"
                                            >
                                                <!-- eslint-disable vue/no-v-html -->
                                                <span
                                                    class="black--text"
                                                    v-html="faq.answer"
                                                ></span>
                                                <!-- eslint-enable -->
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-expansion-panels
                                        v-for="(faq, index) in faq2"
                                        :key="index"
                                        flat
                                        class="mb-4"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header
                                                class="font-weight-medium text-subtitle-1"
                                            >
                                                {{ faq.question }}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                class="text-body-2"
                                            >
                                                <!-- eslint-disable vue/no-v-html -->
                                                <span
                                                    class="black--text"
                                                    v-html="faq.answer"
                                                ></span>
                                                <!-- eslint-enable -->
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="pb-md-10">
            <v-row justify="center" class="align-center pt-md-6">
                <v-col cols="12" lg="9" xl="7" class="text-center">
                    <v-btn
                        v-if="options?.booking_url"
                        :large="$vuetify.breakpoint.mdAndUp"
                        class="primary"
                        :href="options?.booking_url"
                        target="_blank"
                    >
                        Book Appointment
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component({
    name: 'Book'
})
export default class Book extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    faq1 = [
        {
            question: 'What Is An Amplification Campaign?',
            answer: "It's our method of creating content  in the form of videos, articles, blogs, infographics, audio ads, and slideshows. We then repurpose it, and distribute it in multiple high traffic news outlets.   This level of publishing is highly effective and saves you time while getting the attention of the biggest & best traffic source online. <br /><br />This results in you being found by your best customers for products you offer that they're currently researching."
        },
        {
            question: 'What Work Will I Have To Do?',
            answer: 'We handle everything.  The research, the writing, and the publishing.  You may send over information about your business, but this is optional.   We have years of experiencing doing this and getting great results for our clients.'
        }
    ];

    faq2 = [
        {
            question: 'How Much Will I Have To Invest?',
            answer: 'Every business is different and has different goals.  Therefore it’s hard to gauge what your needs will be without first having a discovery call.  From there, we can get more insights into what you want to achieve and give you a custom plan tailored to your business.'
        }
    ];
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.clientcabin-book::v-deep {
    background-color: $white;
    color: $black;

    .headline {
        font-size: 48px !important;
        line-height: 60px !important;
        letter-spacing: 0.02em;
        font-weight: 700;
        color: #282b30;
    }

    .subheadline {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: 0.035em;
        font-weight: 700;
        color: #282b30;
    }

    .subtitle {
        font-size: 20px;
        line-height: 36px;
    }

    .faq {
        .v-expansion-panels {
            border: 1px solid $tertiary-color;
            border-radius: 9px;

            .v-expansion-panel {
                background-color: transparent;
            }
        }

        .v-expansion-panel-header {
            color: $black !important;

            .v-expansion-panel-header__icon svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
